import React from 'react'
import './SelectScreen.css'
import { useAuth } from './Auth';

function SelectScreen() {
    const { setKindButton } = useAuth();
    return (
        <div className='selectWholeScreen'>
            <h1 style={{fontFamily:'digit'}}>Lucky</h1>
            <br/>
            <p className='title'>အမျိုးအစား ရွေးချယ်ပါ</p>
            <div className='selectBox'>
                <p onClick={() => setKindButton('2d')}>2D</p>
            </div><div className='selectBox'>
                <p onClick={() => setKindButton('3d')}>3D</p>
            </div>
            <p style={{marginTop:15, fontFamily:'mmfont', color:'red', textAlign:'center', lineHeight:2}}>သတိပြုရန် - စာရင်းသွင်းမည့် 2D (သို့) 3D ကို မှန်မှန်ကန်ကန်ရွေးပေးပါ။ စာရင်းမှားသွင်းမိပါက စာရင်းများကို ပြန်ကူးယူ၍ မရပါ။ မှားသွင်းထားသည့်စာရင်းများကို ပြန်ဖျက်ပြီးမှ အားလုံးကို အသစ်ပြန်သွင်းရပါမည်။</p>
            <div style={{marginTop:10, display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                <div style={{width:'100%', height:0.5, backgroundColor:'rgba(0,0,0,0.1)'}}/>
                <p style={{display:'flex', flexDirection:'row', width:'280px', textAlign:'center', justifyContent:'center'}}>Explore below</p>
                <div style={{width:'100%', height:0.5, backgroundColor:'rgba(0,0,0,0.1)'}}/>
            </div>
        </div>
    )
}

export default SelectScreen